<script>
    import { createEventDispatcher } from "svelte";


    export let showOnPx = 150;
    export let selectedPageIndex
    let hidden = true;
  const dispatch = createEventDispatcher();

    function goTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        dispatch("update",0)
    }

    function scrollContainer() {
        return document.documentElement || document.body;
    }

    function handleOnScroll() {
        if (!scrollContainer()) {
            return;
        }

        if (scrollContainer().scrollTop > showOnPx) {
            hidden = false;
        } else {
            hidden = true;
        }
    }
</script>

<svelte:window on:scroll={handleOnScroll} />

<div
    class="back-to-top cursor-pointer inline-block p-3 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out hidden bottom-5 right-5 fixed"
    on:click={goTop}
    class:hidden
>
    <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        class="w-4 h-4"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
    >
        <path
            fill="currentColor"
            d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"
        />
    </svg>
</div>

<style>.back-to-top {
  opacity: 1;
  transition: opacity 0.5s, visibility 0.5s;
  position: fixed;
  z-index: 999999999999;
  right: 20px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  bottom: 20px;
  color: white;
  background-color: black;
}

.back-to-top.hidden {
  opacity: 0;
  visibility: hidden;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL0JhY2tUb1RvcC5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0E7RUFDSSxVQUFVO0VBQ1YseUNBQXlDO0VBQ3pDLGVBQWU7RUFDZixxQkFBcUI7RUFDckIsV0FBVztFQUNYLHlCQUFpQjtLQUFqQixzQkFBaUI7TUFBakIscUJBQWlCO1VBQWpCLGlCQUFpQjtFQUNqQixZQUFZO0VBQ1osWUFBWTtFQUNaLHVCQUF1QjtBQUMzQjs7QUFFQTtFQUNJLFVBQVU7RUFDVixrQkFBa0I7QUFDdEIiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvQmFja1RvVG9wLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuLmJhY2stdG8tdG9wIHtcbiAgICBvcGFjaXR5OiAxO1xuICAgIHRyYW5zaXRpb246IG9wYWNpdHkgMC41cywgdmlzaWJpbGl0eSAwLjVzO1xuICAgIHBvc2l0aW9uOiBmaXhlZDtcbiAgICB6LWluZGV4OiA5OTk5OTk5OTk5OTk7XG4gICAgcmlnaHQ6IDIwcHg7XG4gICAgdXNlci1zZWxlY3Q6IG5vbmU7XG4gICAgYm90dG9tOiAyMHB4O1xuICAgIGNvbG9yOiB3aGl0ZTtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiBibGFjaztcbn1cblxuLmJhY2stdG8tdG9wLmhpZGRlbiB7XG4gICAgb3BhY2l0eTogMDtcbiAgICB2aXNpYmlsaXR5OiBoaWRkZW47XG59XG4iXX0= */</style>
