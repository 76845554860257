<div class="modal" />

<style>.modal {
  display: block;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8)
    url("https://i.stack.imgur.com/FhHRx.gif") 50% 50% no-repeat;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL1ByaW50TG9hZGVyLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNFLGNBQWM7RUFDZCxlQUFlO0VBQ2YsYUFBYTtFQUNiLE1BQU07RUFDTixPQUFPO0VBQ1AsWUFBWTtFQUNaLFdBQVc7RUFDWDtnRUFDOEQ7QUFDaEUiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvUHJpbnRMb2FkZXIuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4ubW9kYWwge1xuICBkaXNwbGF5OiBibG9jaztcbiAgcG9zaXRpb246IGZpeGVkO1xuICB6LWluZGV4OiAxMDAwO1xuICB0b3A6IDA7XG4gIGxlZnQ6IDA7XG4gIGhlaWdodDogMTAwJTtcbiAgd2lkdGg6IDEwMCU7XG4gIGJhY2tncm91bmQ6IHJnYmEoMjU1LCAyNTUsIDI1NSwgMC44KVxuICAgIHVybChcImh0dHBzOi8vaS5zdGFjay5pbWd1ci5jb20vRmhIUnguZ2lmXCIpIDUwJSA1MCUgbm8tcmVwZWF0O1xufVxuIl19 */</style>
