<script>
  import axios from "axios";
  import { afterUpdate, onMount } from "svelte";
  import Modal from "../../components/Modal.svelte";
  import ModalUpdateWorkspace from "../../components/ModalUpdateWorkspace.svelte";
  import { Modals, closeModal, openModal, modals } from "svelte-modals";
  import { workspace } from "../../store/store";
  import { Link, navigate } from "svelte-routing";
  import SimpleCrypto from "simple-crypto-js";
  import SuppresionWkModal from "../../components/SuppresionWkModal.svelte";
  import Neopolis from "../../assets/svg/neopolis.svelte";
  import Banner from "../../assets/svg/banner.svelte";
  import privilegeData from "../../constants/privillege";
  import Swal from "sweetalert2";
  import Header from "../../components/Header.svelte";
  import { _ } from "../../services/i18n";
  import { StartWorksapceTutorial } from "../../utils/tutorial";
  // Code only for Replit
  let simpleCrypto = new SimpleCrypto("s6d1f53sdq1f351sd31f3sdq315f135sd1f");
  let privillegeTab = simpleCrypto.decrypt(localStorage.getItem("privilleges"));

  let workspaces = [];
  let ws = [];
  let target;
  let newworkspaceName = "";
  let user = localStorage.getItem("user");

  let openDropDown = null;
  afterUpdate(() => {
    StartWorksapceTutorial();
  });
  onMount(async () => {
    var config = {
      method: "get",
      url: "https://ws.beta.machine-editique.neopolis-dev.com/" + "/workspace/workspaces",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    };

    axios(config)
      .then((res) => {

        ws = res.data;
        workspaces = ws.sort((a, b) =>
          a.creation_date > b.creation_date
            ? -1
            : a.creation_date === b.creation_date &&
              a.creation_time > b.creation_time
            ? -1
            : 1
        );
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",

          text: $_(
            "workspace.Vous n'êtes pas autorisé(e) à consulter la liste des projets."
          ),
        });
      });
  });

  const disconnect = () => {
    localStorage.clear();
    window.location.pathname = "/";
  };
  function handleOpen() {
    if (
      privillegeTab &&
      privillegeTab.find((q) => q.id === privilegeData["CREATE WORKSPACES"])
    ) {
      openModal(Modal, {
        title: `Alert #${$modals.length + 1}`,
        message: "This is an alert",
        onOpenAnother: () => {
          handleOpen();
        },
      });
    }
  }

  function handleOpenUpdate(id, name) {
    if (
      privillegeTab &&
      privillegeTab.find((q) => q.id === privilegeData["UPDATE WORKSPACES"])
    ) {
      openModal(
        ModalUpdateWorkspace,

        {
          id: id,
          name: name,

          onOpenAnother: () => {
            handleOpenUpdate();
          },
        }
      );
    }
  }

  function openModalSuppression(id) {
    openModal(SuppresionWkModal, {
      id: id,
    });
  }

  function goToWorkspace(wk) {
    if (
      privillegeTab &&
      privillegeTab.find((q) => q.id === privilegeData["CONSULT MODELS"])
    ) {
      localStorage.setItem("-", simpleCrypto.encrypt(wk));
      workspace.set(wk);
      navigate("/HomeWorkspace");
    }
  }

  // function deleteWorkspace(wk) {
  //   var config = {
  //     method: "delete",
  //     url: "https://ws.beta.machine-editique.neprivillegeExistopolis-dev.com//workspace/delete",
  //     headers: {
  //       Authorization: "Bearer " + localStorage.getItem("access_token"),
  //     },
  //     data: {
  //       id: wk,
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       window.location.reload()
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }
</script>

<div class="min-h-full">
  <!-- <nav style="background-color: #151416;">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="flex h-16 items-center justify-between">
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <Neopolis />
          </div>
        </div>

        <div class="text-white flex ">
          <svg
            class="mr-2"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.12 11.78C11.0403 11.77 10.9597 11.77 10.88 11.78C10.032 11.7514 9.22821 11.3944 8.63856 10.7842C8.04891 10.174 7.71953 9.35852 7.72001 8.50999C7.72001 6.69999 9.18001 5.22999 11 5.22999C11.8594 5.22849 12.6851 5.5648 13.2988 6.16639C13.9126 6.76798 14.2654 7.58668 14.2811 8.44597C14.2969 9.30527 13.9743 10.1363 13.383 10.76C12.7917 11.3837 11.9789 11.75 11.12 11.78V11.78ZM17.74 18.38C15.9023 20.0691 13.496 21.0044 11 21C8.40001 21 6.04001 20.01 4.26001 18.38C4.36001 17.44 4.96001 16.52 6.03001 15.8C8.77001 13.98 13.25 13.98 15.97 15.8C17.04 16.52 17.64 17.44 17.74 18.38V18.38Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11 21C16.523 21 21 16.523 21 11C21 5.477 16.523 1 11 1C5.477 1 1 5.477 1 11C1 16.523 5.477 21 11 21Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {user}
        </div>
        <div class="hidden md:block">
          <div class="ml-4 flex items-center md:ml-6">
        
            <div class="relative ml-3">
              <div>
                <button
                  type="button"
                  class="flex max-w-xs items-center rounded-full  text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 text-white"
                  id="user-menu-button"
                  aria-expanded="false"
                  aria-haspopup="true"
                  on:click={disconnect}
                >
                 

                  {$_('home.déconnecter')}
                  <svg
                    class="ml-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style="fill: white;"
                    ><path
                      d="M10 9.408l2.963 2.592-2.963 2.592v-1.592h-8v-2h8v-1.592zm-2-4.408v4h-8v6h8v4l8-7-8-7zm6-3c-1.787 0-3.46.474-4.911 1.295l.228.2 1.396 1.221c1.004-.456 2.114-.716 3.287-.716 4.411 0 8 3.589 8 8s-3.589 8-8 8c-1.173 0-2.283-.26-3.288-.715l-1.396 1.221-.228.2c1.452.82 3.125 1.294 4.912 1.294 5.522 0 10-4.477 10-10s-4.478-10-10-10z"
                    /></svg
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="-mr-2 flex md:hidden">
  
          <button
            type="button"
            class="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
           
            <svg
              class="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
            
            <svg
              class="hidden h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

   
    <div class="md:hidden" id="mobile-menu">
      <div class="space-y-1 px-2 pt-2 pb-3 sm:px-3">
  
        <a
          href="/Workspaces"
          class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
          aria-current="page">Dashboard</a
        >

      
      </div>
    </div>
  </nav> -->
  <Header />
  <header class="bg-white shadow">
    <div class="mx-auto max-w-7xl py-6 sm:px-6 sticky">
      <div class="header sticky">
        <div class="ml-20" />
        <div class="headerDiv">
          <Banner />
        </div>
        <div class="rounded-md shadow">
          <button
            style="background-color:{privillegeTab &&
            privillegeTab.find(
              (q) => q.id === privilegeData['CREATE WORKSPACES']
            )
              ? '#F3EAF8'
              : '#DCDCDC'};color:{privillegeTab &&
            privillegeTab.find(
              (q) => q.id === privilegeData['CREATE WORKSPACES']
            )
              ? '#151416'
              : '#ffffff'}
              "
            class="projectBtn flex w-full items-center justify-center rounded-md border border-transparent px-8 py-3 font-medium md:py-4 md:px-10 font Roboto text-xs"
            on:click={handleOpen}
          >
            {#if privillegeTab && privillegeTab.find((q) => q.id === privilegeData["CREATE WORKSPACES"])}
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-4"
              >
                <path
                  d="M1 10.576V9.4C1 3.4 3.4 1 9.4 1H16.6C22.6 1 25 3.4 25 9.4V16.6C25 22.6 22.6 25 16.6 25H15.4"
                  stroke="#151416"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.2 11.7999L20.212 5.77588H15.4M20.212 5.77588V10.5879M11.8 17.9799V21.2199C11.8 23.9199 10.72 24.9999 8.02 24.9999H4.78C2.08 24.9999 1 23.9199 1 21.2199V17.9799C1 15.2799 2.08 14.1999 4.78 14.1999H8.02C10.72 14.1999 11.8 15.2799 11.8 17.9799Z"
                  stroke="#151416"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            {:else}
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-4"
              >
                <path
                  d="M1 10.576V9.4C1 3.4 3.4 1 9.4 1H16.6C22.6 1 25 3.4 25 9.4V16.6C25 22.6 22.6 25 16.6 25H15.4"
                  stroke="#ffffff"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.2 11.7999L20.212 5.77588H15.4M20.212 5.77588V10.5879M11.8 17.9799V21.2199C11.8 23.9199 10.72 24.9999 8.02 24.9999H4.78C2.08 24.9999 1 23.9199 1 21.2199V17.9799C1 15.2799 2.08 14.1999 4.78 14.1999H8.02C10.72 14.1999 11.8 15.2799 11.8 17.9799Z"
                  stroke="#ffffff"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            {/if}
            {$_("workspace.workspaceCreate")}
          </button>
        </div>
      </div>
    </div>
  </header>

  <div>
    <!-- Replace with your content -->
    <div id="container">
      <div class="grid lg:grid-cols-3 md:grid-cols-2 xs:grid-cols-1">
        {#each workspaces as workspace}
          <div
            class="borderDiv max-w-sm bg-white rounded-lg shadow-md dark:bg-gray-800 m-10"
            id={workspace.id}
          >
            <div style="display:flex;justify-content: end;">
              <div class="relative inline-block text-left mb-6">
                <div>
                  <button
                    id="dropdwon"
                    type="button"
                    style="border-radius: 0.5rem;"
                    class="inline-flex w-full justify-center bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                    aria-expanded="true"
                    aria-haspopup="true"
                    on:click={(e) => {
                      if (openDropDown !== null) openDropDown = null;
                      else openDropDown = workspace.id;
                    }}
                  >
                    <!-- Heroicon name: mini/chevron-down -->
                    <svg
                      style="border:none"
                      width="6"
                      height="20"
                      viewBox="0 0 6 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="2.63158"
                        cy="17.3684"
                        r="2.63158"
                        transform="rotate(-90 2.63158 17.3684)"
                        fill="#151416"
                      />
                      <circle
                        cx="2.63158"
                        cy="9.99977"
                        r="2.63158"
                        transform="rotate(-90 2.63158 9.99977)"
                        fill="#151416"
                      />
                      <circle
                        cx="2.63158"
                        cy="2.6316"
                        r="2.63158"
                        transform="rotate(-90 2.63158 2.6316)"
                        fill="#151416"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <!--
              Dropdown menu, show/hide based on menu state.
          
              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
              {#if openDropDown === workspace.id}
                <div
                  class="absolute shadow-2xl z-10 mt-8 w-56 origin-top-right rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabindex="-1"
                  on:click={(e) => (target = e.target)}
                >
                  <div class="py-1" role="none">
                    <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                    <!-- <button  class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">Supprimer</button> -->
                    <span
                      class="mx-3 my-1 cursor-pointer flex flex-row items-center"
                      style="background-color:{privillegeTab &&
                      privillegeTab.find(
                        (q) => q.id === privilegeData['DELETE WORKSPACES']
                      )
                        ? ' #ffffff'
                        : '#DCDCDC'};border-radius: 5px;height:2rem"
                      on:click={() => {
                        if (
                          privillegeTab &&
                          privillegeTab.find(
                            (q) => q.id === privilegeData["DELETE WORKSPACES"]
                          )
                        ) {
                          openDropDown = "";
                          openModalSuppression(workspace.id);
                        }
                      }}
                    >
                      {#if privillegeTab && privillegeTab.find((q) => q.id === privilegeData["DELETE WORKSPACES"])}
                        <svg
                          class="mr-3"
                          width="30"
                          height="30"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="36" height="36" rx="5" fill="#F9DBE0" />
                          <path
                            d="M14.8099 8L11.1899 11.63M21.1899 8L24.8099 11.63"
                            stroke="#FF3459"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8 13.8501C8 12.0001 8.99 11.8501 10.22 11.8501H25.78C27.01 11.8501 28 12.0001 28 13.8501C28 16.0001 27.01 15.8501 25.78 15.8501H10.22C8.99 15.8501 8 16.0001 8 13.8501Z"
                            stroke="#FF3459"
                            stroke-width="1.5"
                          />
                          <path
                            d="M15.76 20V23.55M20.36 20V23.55M9.5 16L10.91 24.64C11.23 26.58 12 28 14.86 28H20.89C24 28 24.46 26.64 24.82 24.76L26.5 16"
                            stroke="#FF3459"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      {:else}
                        <svg
                          class="mr-3"
                          width="30"
                          height="30"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="36" height="36" rx="5" fill="#DCDCDC" />
                          <path
                            d="M14.8099 8L11.1899 11.63M21.1899 8L24.8099 11.63"
                            stroke="#ffffff"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8 13.8501C8 12.0001 8.99 11.8501 10.22 11.8501H25.78C27.01 11.8501 28 12.0001 28 13.8501C28 16.0001 27.01 15.8501 25.78 15.8501H10.22C8.99 15.8501 8 16.0001 8 13.8501Z"
                            stroke="#ffffff"
                            stroke-width="1.5"
                          />
                          <path
                            d="M15.76 20V23.55M20.36 20V23.55M9.5 16L10.91 24.64C11.23 26.58 12 28 14.86 28H20.89C24 28 24.46 26.64 24.82 24.76L26.5 16"
                            stroke="#ffffff"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      {/if}
                      <p
                        style="color:{privillegeTab &&
                        privillegeTab.find(
                          (q) => q.id === privilegeData['DELETE WORKSPACES']
                        )
                          ? '#000000 '
                          : '#ffffff'}"
                      >
                        {$_("workspace.supprimer")}
                      </p>
                    </span>

                    <!-- <button  class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">Modifier</button> -->
                    <span
                      class="mx-3 cursor-pointer flex flex-row items-center"
                      style="background-color:{privillegeTab &&
                      privillegeTab.find(
                        (q) => q.id === privilegeData['UPDATE WORKSPACES']
                      )
                        ? ' #ffffff'
                        : '#DCDCDC'}; border-radius: 5px; height:2rem"
                      on:click={() => {
                        openDropDown = "";
                        handleOpenUpdate(workspace.id, workspace.name);
                      }}
                    >
                      <!-- Heroicon name: mini/pencil -->
                      {#if privillegeTab && privillegeTab.find((q) => q.id === privilegeData["UPDATE WORKSPACES"])}
                        <svg
                          class="mr-3"
                          width="30"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="36" height="36" rx="5" fill="#945FAF" />
                          <path
                            d="M16.8882 8.24854H14.9131C9.97517 8.24854 8 10.2237 8 15.1616V21.0871C8 26.025 9.97517 28.0002 14.9131 28.0002H20.8386C25.7765 28.0002 27.7517 26.025 27.7517 21.0871V19.1119"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M21.8657 9.25544L14.0836 17.0376C13.7873 17.3339 13.491 17.9165 13.4318 18.3412L13.0071 21.3138C12.8491 22.3903 13.6095 23.1409 14.686 22.9927L17.6586 22.5681C18.0734 22.5088 18.6561 22.2125 18.9622 21.9163L26.7444 14.1341C28.0875 12.791 28.7196 11.2306 26.7444 9.25544C24.7692 7.28028 23.2088 7.91233 21.8657 9.25544V9.25544Z"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      {:else}
                        <svg
                          class="mr-3"
                          width="30"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="36" height="36" rx="5" fill="#DCDCDC" />
                          <path
                            d="M16.8882 8.24854H14.9131C9.97517 8.24854 8 10.2237 8 15.1616V21.0871C8 26.025 9.97517 28.0002 14.9131 28.0002H20.8386C25.7765 28.0002 27.7517 26.025 27.7517 21.0871V19.1119"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M21.8657 9.25544L14.0836 17.0376C13.7873 17.3339 13.491 17.9165 13.4318 18.3412L13.0071 21.3138C12.8491 22.3903 13.6095 23.1409 14.686 22.9927L17.6586 22.5681C18.0734 22.5088 18.6561 22.2125 18.9622 21.9163L26.7444 14.1341C28.0875 12.791 28.7196 11.2306 26.7444 9.25544C24.7692 7.28028 23.2088 7.91233 21.8657 9.25544V9.25544Z"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      {/if}
                      <p
                        style="color:{privillegeTab &&
                        privillegeTab.find(
                          (q) => q.id === privilegeData['UPDATE WORKSPACES']
                        )
                          ? '#000000 '
                          : '#ffffff'}"
                      >
                        {$_("workspace.modifier")}
                      </p>
                    </span>
                  </div>
                </div>{/if}
            </div>

            <div class="flex flex-col items-center pb-10">
              <h5 class="mb-10 text-lg font-medium font-Roboto">
                {workspace.name}
              </h5>
              <!-- <svg width="131" height="131" viewBox="0 0 131 131" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="65.5" cy="65.5" r="65.5" fill="#D9D9D9"/>
              </svg> -->
              {#if workspace.logo_b64}
                <img
                  class="mb-3 w-24 h-24 rounded-full shadow-lg"
                  src={workspace.logo_b64}
                  alt="Bonnie image"
                />{/if}
              {#if !workspace.logo_b64}
                <svg
                  class="rounded-full shadow-lg mb-3 w-24 h-24"
                  width="88"
                  height="71"
                  viewBox="0 0 88 71"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M79.9 33.1C78.4 32 73.5 29.7 65.2 26.1C60.3 24 57.7 21.5 57.4 18.6C57.4 18.4 57.3 18.1 57.3 17.9C57.3 15.3 58.2 13 59.9 11C61.6 9 63.8 8 66.3 8C68.4 8 70 8.4 71.1 9.2C72.2 10 73.5 11.6 74.8 14L81.9 10.8C80.8 7.6 78.8 5 75.9 3.1C73.1 1.2 69.9 0 66.3 0C64.7 0 59.2 0 58 0H13.3C17.1 0.4 20.5 1.9 23.5 4.5C27 7.6 29 11.5 29.5 16.3C29.5 16.6 29.6 16.9 29.6 17.2C29.6 17.5 29.6 17.8 29.6 18C29.6 22.5 28.1 26.4 25.1 29.7C22.1 33 18.3 35.1 13.7 36C13.3 36.1 12.8 36.2 12.2 36.2C11.6 36.2 11.1 36.3 10.6 36.3C8.3 36.3 6.2 35.8 4.2 34.9C2.7 33.9 1.4 33 0 32V58C0 64.6 5.4 70 12 70H22.4V56.3C22.4 52.3 23.7 48.9 26.4 46.2C29 43.4 32.3 42.1 36.2 42.1C40 42.1 43.3 43.5 46 46.3C48.7 49.1 50.1 52.4 50.1 56.3V70.3H58.1H66.1C66.1 70.3 66.7 70.3 67 70.3C67.2 70.3 67.4 70.3 67.6 70.3C73 70.3 77.7 68.3 81.7 64.3C85.7 60.3 87.8 55.5 87.8 49.9C87.7 42.6 85.1 37 79.9 33.1Z"
                    fill="#945FAF"
                  />
                  <path
                    d="M30.9998 65.4001H41.0998C41.4998 65.4001 41.7998 65.4001 41.8998 65.4001C42.4998 65.4001 42.8998 65.4001 42.9998 65.3001C43.3998 65.2001 43.6998 65.0001 43.9998 64.7001C44.1998 64.5001 44.2998 64.3001 44.3998 64.0001C44.4998 63.7001 44.5998 63.4001 44.5998 62.9001C44.5998 62.4001 44.5998 62.0001 44.5998 61.7001V55.8001C44.5998 53.3001 43.7998 51.2001 42.1998 49.5001C40.4998 47.6001 38.0998 46.8001 35.1998 47.0001C33.3998 47.1001 31.6998 47.9001 30.4998 49.3001C28.6998 51.3001 27.7998 53.8001 27.7998 56.6001V61.9001C27.7998 62.4001 27.7998 62.8001 27.7998 63.0001C27.7998 63.6001 27.8998 64.0001 28.1998 64.4001C28.4998 64.7001 28.7998 65.0001 29.2998 65.1001C29.7998 65.4001 30.2998 65.4001 30.9998 65.4001Z"
                    fill="#151416"
                  />
                  <path
                    d="M12 29.7002C18.6274 29.7002 24 24.3276 24 17.7002C24 11.0728 18.6274 5.7002 12 5.7002C5.37258 5.7002 0 11.0728 0 17.7002C0 24.3276 5.37258 29.7002 12 29.7002Z"
                    fill="#151416"
                  />
                </svg>
              {/if}
            </div>

            <div
              class=" footer flex mt-4 space-x-3 md:mt-6 p-4 items-center rounded-b-lg justify-between"
            >
              <div class="flex flex-row">
                <svg
                  width="30"
                  height="50"
                  viewBox="0 0 22 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.5 1V4.3M15.3 1V4.3M1.55 8.799H20.25M20.8 8.15V17.5C20.8 20.8 19.15 23 15.3 23H6.5C2.65 23 1 20.8 1 17.5V8.15C1 4.85 2.65 2.65 6.5 2.65H15.3C19.15 2.65 20.8 4.85 20.8 8.15Z"
                    stroke="#945FAF"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.9643 13.8701H14.9742M14.9643 17.1701H14.9742M10.8943 13.8701H10.9053M10.8943 17.1701H10.9053M6.82324 13.8701H6.83424M6.82324 17.1701H6.83424"
                    stroke="#945FAF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div class="flex flex-col ml-4">
                  <span class="text-sm text-gray-500 dark:text-gray-400">
                    {$_("workspace.date")}
                  </span>
                  <span class="text-sm text-gray-500 dark:text-gray-700"
                    >{workspace.creation_date} - {workspace.creation_time}</span
                  >
                </div>
              </div>
              <button
                to="/HomeWorkspace"
                class="accessBtn inline-flex items-center py-2 px-10 text-sm font-medium text-center text-white rounded-lg focus:ring-4 w-5/12 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                style="background-color:{privillegeTab &&
                privillegeTab.find(
                  (q) => q.id === privilegeData['CONSULT MODELS']
                )
                  ? ' #945faf'
                  : '#DCDCDC'};"
                on:click={() => goToWorkspace(workspace)}
                >{$_("workspace.accéder")}</button
              >
              <!--   <button
                on:click={() => deleteWorkspace(workspace)}
                disabled
                class="inline-flex items-center py-2 px-4 text-sm font-medium text-center text-gray-900 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                >Supprimer</button
              > -->
            </div>
          </div>
        {/each}
      </div>
    </div>
  </div>
</div>

<Modals>
  <div slot="backdrop" class="backdrop" on:click={closeModal} />
</Modals>

<!-- Main modal -->

<!-- <div
  id="authentication-modal"
  data-modal-show="true"
  tabindex="-1"
  aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center"
>
  <div class="relative p-4 w-full max-w-md h-full md:h-auto">
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <button
        type="button"
        class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
        data-modal-toggle="authentication-modal"
      >
        <svg
          aria-hidden="true"
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          ><path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          /></svg
        >
        <span class="sr-only">Close modal</span>
      </button>
      <div class="py-6 px-6 lg:px-8">
        <h3 class="mb-4 text-xl font-medium text-gray-900 dark:text-white">
          Creation d'un nouveau workspace
        </h3>
        <form class="space-y-6" action="#">
          <div>
            <label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >Nom</label
            >
            <input
              name="email"
              id="email"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="Nom"
              required=""
              bind:value={newworkspaceName}
            />
          </div>

          <button
            on:click={AddWorkspace}
            class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >Créer</button
          >
        </form>
      </div>
    </div>
  </div>
</div> -->
<style>#container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headerDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

/* .accessBtn {
  background-color: #945faf;
} */

.footer {
  background-color: #f9f4fb;
}

.borderDiv {
  border: #945faf solid 1px;
}

.projectBtn {
  background-color: #f3eaf8;
}

button:focus {
  outline: none;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9wYWdlcy9Xb3Jrc3BhY2VzL1dvcmtzcGFjZXMuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBQ0UsYUFBYTtFQUNiLDZCQUE2QjtFQUM3QixtQkFBbUI7RUFDbkIsaUJBQWlCO0FBQ25COztBQUNBO0VBQ0UsYUFBYTtFQUNiLG1CQUFtQjtFQUNuQiw4QkFBOEI7RUFDOUIsbUJBQW1CO0FBQ3JCOztBQUNBO0VBQ0UsYUFBYTtFQUNiLG1CQUFtQjtFQUNuQixtQkFBbUI7QUFDckI7O0FBQ0E7RUFDRSxlQUFlO0VBQ2YsTUFBTTtFQUNOLFNBQVM7RUFDVCxRQUFRO0VBQ1IsT0FBTztFQUNQLDhCQUE4QjtBQUNoQzs7QUFDQTs7R0FFRzs7QUFDSDtFQUNFLHlCQUF5QjtBQUMzQjs7QUFDQTtFQUNFLHlCQUF5QjtBQUMzQjs7QUFDQTtFQUNFLHlCQUF5QjtBQUMzQjs7QUFDQTtFQUNFLGFBQWE7QUFDZiIsImZpbGUiOiJzcmMvcGFnZXMvV29ya3NwYWNlcy9Xb3Jrc3BhY2VzLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuI2NvbnRhaW5lciB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGp1c3RpZnktY29udGVudDogc3BhY2UtYXJvdW5kO1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBmbGV4LXdyYXA6IG5vd3JhcDtcbn1cbi5oZWFkZXIge1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogcm93O1xuICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG59XG4uaGVhZGVyRGl2IHtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IHJvdztcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbn1cbi5iYWNrZHJvcCB7XG4gIHBvc2l0aW9uOiBmaXhlZDtcbiAgdG9wOiAwO1xuICBib3R0b206IDA7XG4gIHJpZ2h0OiAwO1xuICBsZWZ0OiAwO1xuICBiYWNrZ3JvdW5kOiByZ2JhKDAsIDAsIDAsIDAuNSk7XG59XG4vKiAuYWNjZXNzQnRuIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogIzk0NWZhZjtcbn0gKi9cbi5mb290ZXIge1xuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZjlmNGZiO1xufVxuLmJvcmRlckRpdiB7XG4gIGJvcmRlcjogIzk0NWZhZiBzb2xpZCAxcHg7XG59XG4ucHJvamVjdEJ0biB7XG4gIGJhY2tncm91bmQtY29sb3I6ICNmM2VhZjg7XG59XG5idXR0b246Zm9jdXMge1xuICBvdXRsaW5lOiBub25lO1xufVxuIl19 */</style>
