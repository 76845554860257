<script>
  export let options = [];
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  let rootElement;
  export let width;
  export let height;
  $: rootElement && rootElement.style.setProperty("--container-width", width);
  $: rootElement && rootElement.style.setProperty("--container-height", height);
</script>

<select
  class="form-select"
  bind:this={rootElement}
  on:change={(e) => {
    dispatch("select", {
      value: e.target.value,
    });
  }}
>
  <option value="" selected>Choisir dans la liste</option>
  {#each options as option}
    <option value={option.text}>{option.text}</option>
  {/each}
</select>

<style>:root {
  --container-width: inherit;
  --container-height: inherit;
}

select {
  display: block;
  top: 0;
  left: 0;
  height: var(--container-height);
  width: var(--container-width);
}

.form-select {
  border-radius: 0.5rem;
  background-color: #959b9e4d;
  color: #4a5568;
  font-size: 1rem;
  cursor: pointer;
}

.form-select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
}


/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL1NlbGVjdE9wdGlvbnMvU2VsZWN0T3B0aW9ucy5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0E7RUFDRSwwQkFBMEI7RUFDMUIsMkJBQTJCO0FBQzdCOztBQUNBO0VBQ0UsY0FBYztFQUNkLE1BQU07RUFDTixPQUFPO0VBQ1AsK0JBQStCO0VBQy9CLDZCQUE2QjtBQUMvQjs7QUFDQTtFQUNFLHFCQUFxQjtFQUNyQiwyQkFBMkI7RUFDM0IsY0FBYztFQUNkLGVBQWU7RUFDZixlQUFlO0FBQ2pCOztBQUVBO0VBQ0UsYUFBYTtFQUNiLDZDQUE2QztBQUMvQyIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9TZWxlY3RPcHRpb25zL1NlbGVjdE9wdGlvbnMuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG46cm9vdCB7XG4gIC0tY29udGFpbmVyLXdpZHRoOiBpbmhlcml0O1xuICAtLWNvbnRhaW5lci1oZWlnaHQ6IGluaGVyaXQ7XG59XG5zZWxlY3Qge1xuICBkaXNwbGF5OiBibG9jaztcbiAgdG9wOiAwO1xuICBsZWZ0OiAwO1xuICBoZWlnaHQ6IHZhcigtLWNvbnRhaW5lci1oZWlnaHQpO1xuICB3aWR0aDogdmFyKC0tY29udGFpbmVyLXdpZHRoKTtcbn1cbi5mb3JtLXNlbGVjdCB7XG4gIGJvcmRlci1yYWRpdXM6IDAuNXJlbTtcbiAgYmFja2dyb3VuZC1jb2xvcjogIzk1OWI5ZTRkO1xuICBjb2xvcjogIzRhNTU2ODtcbiAgZm9udC1zaXplOiAxcmVtO1xuICBjdXJzb3I6IHBvaW50ZXI7XG59XG5cbi5mb3JtLXNlbGVjdDpmb2N1cyB7XG4gIG91dGxpbmU6IG5vbmU7XG4gIGJveC1zaGFkb3c6IDAgMCAwIDJweCByZ2JhKDY2LCAxNTMsIDIyNSwgMC41KTtcbn1cblxuIl19 */</style>
