<script>
  import {  createEventDispatcher } from "svelte";
  let rootElement;
  const dispatch = createEventDispatcher();
  let check = false
  export let height;
  $: rootElement &&
    rootElement.style.setProperty("--scale-checkbox", Math.round(height/21.48));
</script>
<div class="flex items-center justify-center" style="height: {height}px;">

  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" bind:this={rootElement} on:click={() => {
    check = !check
    dispatch("check", {
      state: check ? "True":"False",
    });
  }}>
</div>

<style>:root {
  --scale-checkbox: inherit;
}

.form-check-input{
  cursor: pointer;
}

#flexCheckDefault{
  height: 100%;
  width: 100%;
}


/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL0NoZWNrQm94L0NoZWNrQm94LnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNFLHlCQUF5QjtBQUMzQjs7QUFDQTtFQUNFLGVBQWU7QUFDakI7O0FBQ0E7RUFDRSxZQUFZO0VBQ1osV0FBVztBQUNiIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL0NoZWNrQm94L0NoZWNrQm94LnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuOnJvb3Qge1xuICAtLXNjYWxlLWNoZWNrYm94OiBpbmhlcml0O1xufVxuLmZvcm0tY2hlY2staW5wdXR7XG4gIGN1cnNvcjogcG9pbnRlcjtcbn1cbiNmbGV4Q2hlY2tEZWZhdWx0e1xuICBoZWlnaHQ6IDEwMCU7XG4gIHdpZHRoOiAxMDAlO1xufVxuXG4iXX0= */</style>
