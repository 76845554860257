<script>
  import SimpleCrypto from "simple-crypto-js";
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";
  import { closeModal, Modals, modals, openModal } from "svelte-modals";

  import Modal from "../../components/Modal.svelte";
  import Selection from "../Selection/Selection.svelte";
  import Swal from "sweetalert2";
  import { getAsset } from "../../utils/prepareAssets";
  import { readAsArrayBuffer } from "../../utils/asyncReader";
  import { v4 as uuidv4 } from "uuid";
  import axios from "axios";
  import ImportModal from "../../components/ImportModal.svelte";
  import Banner from "../../assets/svg/banner.svelte";
  import Editique from "../../assets/svg/editique.svelte";
  import { _ } from "../../services/i18n";
  import Header from "../../components/Header.svelte";

  import privilegeData from "../../constants/privillege";
  import { StartModelTutorial } from "../../utils/tutorial";

  let privillegeTab = [];
  export let location;
  let message = false;

  let data = {};
  let user = localStorage.getItem("user");
  let company_id = localStorage.getItem("company_id");

  onMount(() => {
    let simpleCrypto = new SimpleCrypto("s6d1f53sdq1f351sd31f3sdq315f135sd1f");
    let prevs = localStorage.getItem("privilleges");
    let workspacecrypted = localStorage.getItem("-");
    if (prevs) {
      privillegeTab = simpleCrypto.decrypt(prevs);
    }
    data = simpleCrypto.decrypt(workspacecrypted);
  });
  const disconnect = () => {
    localStorage.clear();
    navigate("/");
  };
  function handleOpen() {
    openModal(Modal, {
      title: `Alert #${$modals.length + 1}`,
      message: "This is an alert",
      onOpenAnother: () => {
        handleOpen();
      },
    });
  }

  function copy() {
    navigator.clipboard
      .writeText(localStorage.getItem("api_key"))
      .then(() => {
        document.getElementById("indicator").style =
          "display:block;position:absolute;top:-10px;left:30px; font-size:1rem;font-weight:400";
      })
      .catch(() => {
        document.getElementById("indicator").style = "display:none";
      });
    setTimeout(() => {
      document.getElementById("indicator").style = "display:none";
    }, 1000);
  }
  function handleOpenImport() {
    if (privillegeTab.find((q) => q.id === privilegeData["CREATE MODELS"])) {
      openModal(ImportModal, {
        onOpenAnother: () => {
          handleOpenImport();
        },
      });
    }
  }
  async function importPDF(e) {
    e.preventDefault();
    const files = e.target.files || (e.dataTransfer && e.dataTransfer.files);
    const file = files[0];
    if (!file || file.type !== "application/pdf")
      return Swal.fire(
        $_("workspace.Il faut selectionnez un fichier PDF"),
        $_("workspace.Merci de verifier le type de votre fichier"),
        "question"
      );
    try {
      const PDFLib = await getAsset("pdfjsLib");
      const data = await readAsArrayBuffer(file);
      PDFLib.getDocument({ data }).then((promise) => {
        promise.getMetadata().then((data) => {
          if (data.info.Keywords) {
            createNewModel(data.info.Keywords, file);
          } else {
            Swal.fire(
              $_("workspace.Il faut selectionnez un fichier PDF exporté"),
              $_("workspace.Merci de verifier le type de votre fichier"),
              "question"
            );
          }
        });
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function createNewModel(data, fileselected) {
    try {
      let simpleCrypto = new SimpleCrypto("s6d1f53sdq1f351sd31f3sdq315f135sd1f");
      let userdata = localStorage.getItem("-");
      let workspace = simpleCrypto.decrypt(userdata);
      const file = new FormData();
      file.append("file", fileselected);
      file.append("workspace_id", workspace.id);
      let uuid = uuidv4();
      axios
        .post("https://ws.beta.machine-editique.neopolis-dev.com/" + "/editique_pdf/upload", file, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((res) => {
          axios
            .post("https://ws.beta.machine-editique.neopolis-dev.com/" + "/editique_pdf/setFields", {
              data: JSON.parse(data),
              fileurl: res.data.file_url,
              idModel: uuid,
              modelName: "Exported File" + Date.now(),
              workspace_id: workspace.id,
              company_id: company_id,
            })
            .then((res) => {
              closeModal();
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: $_("workspace.Model copied successfully"),
                showConfirmButton: false,
                timer: 1500,
              }).then(() => (window.location.href = "/HomeWorkspace"));
            })
            .catch((error) => console.log(error));
        })
        .catch((err) =>
          Swal.fire({
            icon: "error",
            // title: "Oops...",
            text: err.response.data.message,
          })
        );
    } catch (e) {
      Swal.fire({
        icon: "error",
        // title: "Oops...",
        text: $_("workspace.Verifier l'intégrité  de votre fichier"),
      });
      Swal.fire({
        icon: "error",
        // title: "Oops...",
        text: $_("workspace.Failed to add pdf"),
      });
      throw e;
    }
  }
</script>

<div class="h-full relative overflow-hidden bg-white">
  <div>
    <div class="  bg-white pb-8 sm:pb-16 md:pb-20 xl:pb-32">
      <div>
        <Header />
        <header class="bg-white shadow">
          <div class="mx-auto max-w-7xl py-6 sm:px-6">
            <div class="header sticky">
              <div class="ml-5">
                <button
                  on:click={() => navigate("/Workspaces")}
                  class="retoutbtn"
                  ><svg
                    class="mr-4"
                    width="30"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.6 18L14 18M18 2C9.1632 2 2 9.1632 2 18C2 26.8368 9.1632 34 18 34C26.8368 34 34 26.8368 34 18C34 9.1632 26.8368 2 18 2Z"
                      stroke="white"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.2002 13.1998L12.4002 17.9998L17.2002 22.7998"
                      stroke="white"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {$_("workspace.retour")}</button
                >
              </div>
              <div class="headerDiv">
                <Banner />
              </div>
              <div class="rounded-md shadow px-8" />
            </div>
          </div>
        </header>

       
      </div>

      <main
        class=" flex flex-column mt-20"
        style="justify-content: space-around;"
      >
        <div>
          <div class="sm:text-center lg:text-left">
            <div class="flex flex-row">
              <span class=" text-4xl font-medium mr-4"
                >{$_("workspace.projet")}</span
              >
              <span
                class=" font-Roboto font-medium text-3xl mr-20"
                style="margin-top:0.5rem"
              >
                {data.name}
              </span>
            </div>

            <div>
              <h1
                class="text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl md:text-2xl flex flex-row mt-6"
              >
                <span class=" font-Roboto font-bold mr-6 text-2xl">API KEY</span
                >
                <span class=" font-Roboto font-medium text-2xl mr-2">
                  {localStorage
                    .getItem("api_key")
                    .substr(0, 15)}{localStorage.getItem("api_key").length > 15
                    ? "..."
                    : ""}
                </span>
                <span
                  class="mr-20 mt-1 cursor-pointer"
                  style="position: relative;"
                  on:click={copy}
                >
                  <span id="indicator" style="display:none"
                    >{$_("workspace.copié")}!</span
                  >
                  <svg
                    width="22"
                    height="30"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 12.4V15.4C16 19.4 14.4 21 10.4 21H6.6C2.6 21 1 19.4 1 15.4V11.6C1 7.6 2.6 6 6.6 6H9.6"
                      stroke="#945FAF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.6 1H14.6M6 4C6 2.34 7.34 1 9 1H11.62M21 7V13.19C21 14.74 19.74 16 18.19 16M16 12.4H12.8C10.4 12.4 9.6 11.6 9.6 9.2V6L16 12.4ZM21 7H18C15.75 7 15 6.25 15 4V1L21 7Z"
                      stroke="#945FAF"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <div class="flex flex-row">
                  <svg
                    style="margin-top:-0.5rem"
                    width="45"
                    height="60"
                    viewBox="0 0 63 63"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="63" height="63" rx="5" fill="#F3EAF8" />
                    <path
                      d="M23.5556 10V16.3M40.4444 10V16.3M14.0556 24.889H49.9444M51 23.65V41.5C51 47.8 47.8333 52 40.4444 52H23.5556C16.1667 52 13 47.8 13 41.5V23.65C13 17.35 16.1667 13.15 23.5556 13.15H40.4444C47.8333 13.15 51 17.35 51 23.65Z"
                      stroke="#945FAF"
                      stroke-width="2.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M39.9806 35H40M39.9806 41H40M31.9914 35H32.013M31.9914 41H32.013M24 35H24.0216M24 41H24.0216"
                      stroke="#945FAF"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <div class="flex flex-col ml-4">
                    <span
                      class="text-sm text-gray-500 dark:text-gray-400 dateCreation"
                      >{$_("workspace.date")}
                    </span>
                    <span class="text-sm date">
                      {data.creation_date} - {data.creation_time}</span
                    >
                  </div>
                </div>
                <!-- </h1> -->
              </h1>
            </div>

            <div class="mt-5 sm:mt-8 sm:flex text-center">
              <div class="rounded-md shadow">
                <button
                  class=" createButton flex w-full items-center justify-center rounded-md border border-transparent px-8 py-3 font-medium text-white md:py-4 md:px-10 text-xs"
                  style="background-color:{privillegeTab.find(
                    (q) => q.id === privilegeData['CREATE MODELS']
                  )
                    ? '#945FAF '
                    : '#DCDCDC'}"
                  on:click={() => {
                    if (
                      privillegeTab.find(
                        (q) => q.id === privilegeData["CREATE MODELS"]
                      )
                    ) {
                      navigate("/Workspace");
                    }
                  }}
                >
                  <svg
                    class="mr-4"
                    width="20"
                    height="22"
                    viewBox="0 0 20 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.2599 2.43908L3.04985 11.1291C2.73985 11.4591 2.43985 12.1091 2.37985 12.5591L2.00985 15.7991C1.87985 16.9691 2.71985 17.7691 3.87985 17.5691L7.09985 17.0191C7.54985 16.9391 8.17985 16.6091 8.48985 16.2691L16.6999 7.57908C18.1199 6.07908 18.7599 4.36908 16.5499 2.27908C14.3499 0.209084 12.6799 0.939084 11.2599 2.43908V2.43908Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.89 3.88867C10.0996 5.22953 10.7486 6.46281 11.735 7.39492C12.7214 8.32703 13.9894 8.9052 15.34 9.03867M1 20.8387H19"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {$_("workspace.createModel")}</button
                >
              </div>
              <div class="mt-3 sm:mt-0 sm:ml-3">
                <!-- <a href="#" class="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-100 px-8 py-3 text-base font-medium text-indigo-700 hover:bg-indigo-200 md:py-4 md:px-10 md:text-lg">Live demo</a> -->
                <!-- {#each models as model}
<Link class="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-100 px-8 py-3 text-base font-medium text-indigo-700 hover:bg-indigo-200 md:py-4 md:px-10 md:text-lg" to={model.idModel}>{model.modelName} </Link>
{/each} -->
                <!-- <button
                class="addButton flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-100 px-8 py-3  font-medium   md:py-4 md:px-10 text-xs	"
                on:click={importPDF}
              >
              <svg class="mr-4" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.06 15.5V10.5M13.5 13H8.5" stroke="#151416" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M21 10V16C21 20 20 21 16 21H6C2 21 1 20 1 16V6C1 2 2 1 6 1H7.5C9 1 9.33 1.44 9.9 2.2L11.4 4.2C11.78 4.7 12 5 13 5H16C20 5 21 6 21 10Z" stroke="#151416" stroke-width="1.5" stroke-miterlimit="10"/>
                </svg>
                  Importer un modéle
              </button> -->
                <label class="  cursor-pointer" for="pdfexport">
                  <div
                    class=" addButton inline-flex items-center content-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    style="background-color:{privillegeTab.find(
                      (q) => q.id === privilegeData['CREATE MODELS']
                    )
                      ? '#D8EDF9 '
                      : '#DCDCDC'}"
                  >
                    {#if privillegeTab.find((q) => q.id === privilegeData["CREATE MODELS"])}
                      <svg
                        class="mr-4"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.06 15.5V10.5M13.5 13H8.5"
                          stroke="#151416"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M21 10V16C21 20 20 21 16 21H6C2 21 1 20 1 16V6C1 2 2 1 6 1H7.5C9 1 9.33 1.44 9.9 2.2L11.4 4.2C11.78 4.7 12 5 13 5H16C20 5 21 6 21 10Z"
                          stroke="#151416"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                        />
                      </svg>
                    {:else}
                      <svg
                        class="mr-4"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.06 15.5V10.5M13.5 13H8.5"
                          stroke="#ffffff"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M21 10V16C21 20 20 21 16 21H6C2 21 1 20 1 16V6C1 2 2 1 6 1H7.5C9 1 9.33 1.44 9.9 2.2L11.4 4.2C11.78 4.7 12 5 13 5H16C20 5 21 6 21 10Z"
                          stroke="#ffffff"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                        />
                      </svg>
                    {/if}
                    <p
                      style="color:{privillegeTab.find(
                        (q) => q.id === privilegeData['CREATE MODELS']
                      )
                        ? '#151416 '
                        : '#ffffff'}"
                    >
                      {$_("workspace.ImportModel")}
                    </p>
                  </div>
                </label>
                <input
                  name="pdfexport"
                  id="pdfexport"
                  class=" hidden"
                  on:click={handleOpenImport}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Editique />
        </div>
      </main>
    </div>
  </div>

  <Selection />
</div>
<Modals>
  <div slot="backdrop" class="backdrop" on:click={closeModal} />
</Modals>

<style>.addButton {
  background-color: #d8edf9;
  height: 3.5rem;
  padding-inline: 2rem;
}

.createButton {
  background-color: #945faf;
}

.date {
  color: #a6a6a6;
}

.dateCreation {
  color: #c8c8c8;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headerDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.projectBtn {
  background-color: #d9d9d9;
  /* cursor: pointer; */
}

button:focus {
  outline: none;
}

.retoutbtn {
  background-color: #7ac1e9;
  border-radius: 1.2rem;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-block: 0.2rem;
  padding-inline: 1rem;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9wYWdlcy9Ib21lL0hvbWUuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBQ0UseUJBQXlCO0VBQ3pCLGNBQWM7RUFDZCxvQkFBb0I7QUFDdEI7O0FBQ0E7RUFDRSx5QkFBeUI7QUFDM0I7O0FBQ0E7RUFDRSxjQUFjO0FBQ2hCOztBQUNBO0VBQ0UsY0FBYztBQUNoQjs7QUFFQTtFQUNFLGFBQWE7RUFDYixtQkFBbUI7RUFDbkIsOEJBQThCO0VBQzlCLG1CQUFtQjtBQUNyQjs7QUFDQTtFQUNFLGFBQWE7RUFDYixtQkFBbUI7RUFDbkIsbUJBQW1CO0FBQ3JCOztBQUNBO0VBQ0UseUJBQXlCO0VBQ3pCLHFCQUFxQjtBQUN2Qjs7QUFDQTtFQUNFLGFBQWE7QUFDZjs7QUFDQTtFQUNFLHlCQUF5QjtFQUN6QixxQkFBcUI7RUFDckIsWUFBWTtFQUNaLGFBQWE7RUFDYixtQkFBbUI7RUFDbkIsbUJBQW1CO0VBQ25CLHFCQUFxQjtFQUNyQixvQkFBb0I7QUFDdEIiLCJmaWxlIjoic3JjL3BhZ2VzL0hvbWUvSG9tZS5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5hZGRCdXR0b24ge1xuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZDhlZGY5O1xuICBoZWlnaHQ6IDMuNXJlbTtcbiAgcGFkZGluZy1pbmxpbmU6IDJyZW07XG59XG4uY3JlYXRlQnV0dG9uIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogIzk0NWZhZjtcbn1cbi5kYXRlIHtcbiAgY29sb3I6ICNhNmE2YTY7XG59XG4uZGF0ZUNyZWF0aW9uIHtcbiAgY29sb3I6ICNjOGM4Yzg7XG59XG5cbi5oZWFkZXIge1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogcm93O1xuICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG59XG4uaGVhZGVyRGl2IHtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IHJvdztcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbn1cbi5wcm9qZWN0QnRuIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogI2Q5ZDlkOTtcbiAgLyogY3Vyc29yOiBwb2ludGVyOyAqL1xufVxuYnV0dG9uOmZvY3VzIHtcbiAgb3V0bGluZTogbm9uZTtcbn1cbi5yZXRvdXRidG4ge1xuICBiYWNrZ3JvdW5kLWNvbG9yOiAjN2FjMWU5O1xuICBib3JkZXItcmFkaXVzOiAxLjJyZW07XG4gIGNvbG9yOiB3aGl0ZTtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IHJvdztcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgcGFkZGluZy1ibG9jazogMC4ycmVtO1xuICBwYWRkaW5nLWlubGluZTogMXJlbTtcbn1cbiJdfQ== */</style>
